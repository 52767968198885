import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import FormatBold from '@material-ui/icons/FormatBold';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  CircularProgress,
  TextField,
  Switch,
  FormControlLabel,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@material-ui/core";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },

  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const SessionSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
});

const WhatsAppModal = ({ open, onClose, whatsAppId }) => {
  const classes = useStyles();
  const initialState = {
    name: "",
    greetingMessage: "",
    farewellMessage: "",
    isDefault: false,
    isMultidevice: true,
    transferTicketMessage: "",
    voicenotansweredMessage: "",
    cloudapiID: "",
    cloudapiToken: "",
    provider: "beta"
  };
  const [whatsApp, setWhatsApp] = useState(initialState);
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);
  const [useWhatsAppWaba, setUseWhatsAppWaba] = useState(false);

  useEffect(() => {
    const fetchSession = async () => {
      if (!whatsAppId) return;

      try {
        const { data } = await api.get(`whatsapp/${whatsAppId}`);
        setWhatsApp(data);
        setUseWhatsAppWaba(data.provider === "waba");
        const whatsQueueIds = data.queues?.map((queue) => queue.id);
        setSelectedQueueIds(whatsQueueIds);
      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
  }, [whatsAppId]);

  const handleSaveWhatsApp = async (values) => {
    const whatsappData = { ...values, queueIds: selectedQueueIds };

    try {
      if (whatsAppId) {
        await api.put(`/whatsapp/${whatsAppId}`, whatsappData);
      } else {
        if (useWhatsAppWaba) {
          await api.post("/waba/", {
            ...whatsappData,
            cloudapiID: values.cloudapiID,
            cloudapiToken: values.cloudapiToken,
          });
        } else {
          await api.post("/whatsapp", whatsappData);
        }
      }
      toast.success(i18n.t("whatsappModal.success"));
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  const handleClose = () => {
    onClose();
    setWhatsApp(initialState);
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle>
          {whatsAppId ? i18n.t("whatsappModal.title.edit") : i18n.t("whatsappModal.title.add")}
        </DialogTitle>
        <Formik
          initialValues={whatsApp}
          enableReinitialize={true}
          validationSchema={SessionSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveWhatsApp(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, touched, errors, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    label={i18n.t("whatsappModal.form.name")}
                    autoFocus
                    name="name"
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                  />
                  <FormControlLabel
                    control={
                      <Field
                        as={Switch}
                        color="primary"
                        name="isDefault"
                        checked={values.isDefault}
                      />
                    }
                    label={i18n.t("whatsappModal.form.default")}
                  />

                  {/* <FormControlLabel
                    control={
                      <Field
                        as={Switch}
                        color="primary"
                        name="isMultidevice"
                        checked={values.isMultidevice}
                  
                      />
                    }
                    label={i18n.t("whatsappModal.form.isMultidevice")}
                  /> */}
                </div>

                {/* SELECT WHATSAPP OFICIAL */}
                <div>
                  <FormControl variant="outlined" margin="dense" fullWidth>
                    <InputLabel>{i18n.t("Integração")}</InputLabel>
                    <Field
                      as={Select}
                      label={i18n.t("Integração")}
                      name="provider"
                      value={useWhatsAppWaba ? "waba" : "beta"}
                      onChange={(e) => {
                        setUseWhatsAppWaba(e.target.value === "waba");
                        values.provider = e.target.value;
                      }}
                    >
                      <MenuItem value="beta">
                        <FormatBold style={{ marginRight: 8 }} />
                        Beta
                      </MenuItem>
                      <MenuItem value="waba">
                        <WhatsAppIcon style={{ marginRight: 8 }} />
                        WhatsApp Oficial
                      </MenuItem>
                    </Field>
                  </FormControl>
                </div>

                {/* CAMPOS tokenZap E idZap */}
                {useWhatsAppWaba && (
                  <>
                    <div>
                      <Field
                        as={TextField}
                        label="Acess Token"
                        type="text"
                        fullWidth
                        name="cloudapiToken"
                        variant="outlined"
                        margin="dense"
                        value={values.cloudapiToken}
                        onChange={e => { values.cloudapiToken = e.target.value }}
                      />
                    </div>
                    <div>
                      <Field
                        as={TextField}
                        label="WhatsApp numberId"
                        type="text"
                        fullWidth
                        name="cloudapiID"
                        variant="outlined"
                        margin="dense"
                        value={values.cloudapiID}
                        onChange={e => { values.cloudapiID = e.target.value }}
                      />
                    </div>
                  </>
                )}
                
                <div>
                  <Field
                    as={TextField}
                    label={i18n.t("queueModal.form.greetingMessage")}
                    type="greetingMessage"
                    multiline
                    minRows={5}
                    fullWidth
                    name="greetingMessage"
                    error={touched.greetingMessage && Boolean(errors.greetingMessage)}
                    helperText={touched.greetingMessage && errors.greetingMessage}
                    variant="outlined"
                    margin="dense"
                  />
                </div>
                <div>
                  <Field
                    as={TextField}
                    label={i18n.t("whatsappModal.form.farewellMessage")}
                    type="farewellMessage"
                    multiline
                    minRows={5}
                    fullWidth
                    name="farewellMessage"
                    error={touched.farewellMessage && Boolean(errors.farewellMessage)}
                    helperText={touched.farewellMessage && errors.farewellMessage}
                    variant="outlined"
                    margin="dense"
                  />
                </div>
                <div>
                  <Field
                    as={TextField}
                    label={i18n.t("whatsappModal.form.voicenotansweredMessage")}
                    type="voicenotansweredMessage"
                    multiline
                    minRows={5}
                    fullWidth
                    name="voicenotansweredMessage"
                    error={touched.voicenotansweredMessage && Boolean(errors.voicenotansweredMessage)}
                    helperText={touched.voicenotansweredMessage && errors.voicenotansweredMessage}
                    variant="outlined"
                    margin="dense"
                  />
                </div>
                <div>
                  <Field
                    as={TextField}
                    label={i18n.t("whatsappModal.form.transferTicketMessage")}
                    type="transferTicketMessage"
                    multiline
                    minRows={5}
                    fullWidth
                    name="transferTicketMessage"
                    error={touched.transferTicketMessage && Boolean(errors.transferTicketMessage)}
                    helperText={touched.transferTicketMessage && errors.transferTicketMessage}
                    variant="outlined"
                    margin="dense"
                  />
                </div>
                <QueueSelect
                  selectedQueueIds={selectedQueueIds}
                  onChange={(selectedIds) => setSelectedQueueIds(selectedIds)}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("whatsappModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {whatsAppId
                    ? i18n.t("whatsappModal.buttons.okEdit")
                    : i18n.t("whatsappModal.buttons.okAdd")}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default React.memo(WhatsAppModal);
